import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";

function Home() {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage || i18n.defaultLocale;
  return (
    <div id="page.home.">
      <Helmet htmlAttributes={{ lang }}>
        <title>
          {t("base.title")} - {t("navigation.Start")}
        </title>
        <meta
          name="description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
        <meta
          property="og:title"
          content="BLUE OYSTER. DIVING CENTER ATHENS. PADI COURSES & MARINE DEPARTMENT."
        />
        <meta
          property="og:description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
      </Helmet>
      <div className="view--item view--home">
        <div className="view--item__content">
          <h1>
            <span className="fx--home__line">
              {t("page.home.Hero.LineOne")}
            </span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineTwo")}
            </span>
            <span className="view--item__spacer"></span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineThree")}
            </span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineFour")}
            </span>
            <span className="fx--home__line">
              {t("page.home.Hero.LineFive")}
            </span>
          </h1>
          <p className="fx--home__line">
            <Trans
              i18nKey="page.home.Hero.Teaser"
              components={{
                LinkDSD: <Link to="/discover-scuba-diving" />,
                LinkPADICourses: <Link to="/padi-courses" />,
                LinkCertifiedDivers: <Link to="/certified-divers" />,
                LinkMarineDepartment: <Link to="/marine-department" />,
              }}
            />
            <br />
          </p>
          <div className="button-group  fx--home__line">
            <Link to="contact" className="button--cta">
              <span className="button--cta__text">{t("button.ContactUs")}</span>
            </Link>
          </div>

          <button className="button--scroll-down fx--home__down">
            <span className="button--scroll-down__text">
              {t("button.CTAHome")}
            </span>
            <svg
              className="button--scroll-down__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 404.3 404.3"
              fill="#ffffff"
            >
              <path
                d="M290 18L266 0 114 202l152 202 24-18-138-184z"
                stroke="#ffffff"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        className="view--item view--cover view--bg-300"
        style={{
          backgroundImage: "url('/static/images/dsd.jpg')",
        }}
      >
        <div className="view--item__content view--item ">
          <div className="view--item__home-teaser">
            <h1>
              <Trans
                i18nKey="page.home.DSD.Headline"
                components={{
                  br: <br />,
                }}
              />
              <span className="view--item__subline">
                <Trans
                  i18nKey="page.home.DSD.Subline"
                  components={{
                    br: <br />,
                  }}
                />
              </span>{" "}
            </h1>
            <div className="button-group">
              <Link to="/discover-scuba-diving" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ReadMore")}
                </span>
              </Link>
              <Link to="contact" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ContactUs")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="view--item view--cover view--item view--bg-500"
        style={{
          backgroundImage: "url('/static/images/padi.jpg')",
        }}
      >
        <div className="view--item__content  ">
          <div className="view--item__home-teaser">
            <h1>
              <Trans
                i18nKey="page.home.PADICourses.Headline"
                components={{
                  br: <br />,
                }}
              />
              <span className="view--item__subline">
                <Trans
                  i18nKey="page.home.PADICourses.Subline"
                  components={{
                    br: <br />,
                  }}
                />
              </span>
            </h1>
            <div className="button-group">
              <Link to="/padi-courses" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ReadMore")}
                </span>
              </Link>
              <Link to="contact" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ContactUs")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="view--item view--cover view--item view--bg-700"
        style={{
          backgroundImage: "url('/static/images/certified.jpg')",
        }}
      >
        <div className="view--item__content">
          <div className="view--item__home-teaser">
            <h1>
              <Trans
                i18nKey="page.home.CertifiedDivers.Headline"
                components={{
                  br: <br />,
                }}
              />
              <span className="view--item__subline">
                <Trans
                  i18nKey="page.home.CertifiedDivers.Subline"
                  components={{
                    br: <br />,
                  }}
                />
              </span>
            </h1>
            <div className="button-group">
              <Link to="/certified-divers" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ReadMore")}
                </span>
              </Link>
              <Link to="contact" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ContactUs")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="view--item view--cover view--bg-800"
        style={{
          backgroundImage: "url('/static/images/marine.jpg')",
        }}
      >
        <div className="view--item__content  ">
          <div className="view--item__home-teaser">
            <h1>
              <Trans
                i18nKey="page.home.MarineDepartment.Headline"
                components={{
                  br: <br />,
                }}
              />
              <span className="view--item__subline">
                <Trans
                  i18nKey="page.home.MarineDepartment.Subline"
                  components={{
                    br: <br />,
                  }}
                />
              </span>
            </h1>
            <div className="button-group">
              <Link to="/marine-department" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ReadMore")}
                </span>
              </Link>
              <Link to="contact" className="button--cta">
                <span className="button--cta__text">
                  {t("button.ContactUs")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
