import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          base: {
            title:
              "BLUE OYSTER DIVING CENTER ATHENS. PADI COURSES & MARINE DEPARTMENT",
          },
          navigation: {
            Start: "Homepage",
            PADIDSD: "Discover Scuba Diving",
            PADICourses: "PADI Courses & Certifications",
            CertifiedDivers: "Certified Divers",
            MarineDepartment: "Marine Department",
            ContactUs: "Contact Us",
          },
          button: {
            ContactUs: "Contact us!",
            ReadMore: "Read More!",
            CTAHome: "Dive right into it!",
          },
          page: {
            home: {
              Hero: {
                LineOne: "BLUE OYSTER",
                LineTwo: "DIVING CENTER",
                LineThree: "PADI COURSES &",
                LineFour: "UNDERWATER",
                LineFive: "SERVICES",
                Teaser:
                  "You want to <LinkDSD>Discover Scuba diving </LinkDSD>, get a <LinkPADICourses> diving certification</LinkPADICourses>, are a <LinkCertifiedDivers> certified diver</LinkCertifiedDivers> or looking for an experienced <LinkMarineDepartment> Marine Department</LinkMarineDepartment> in Greece? ",
              },
              DSD: {
                Headline: "DISCOVER<br />SCUBA<br />DIVING ",
                Subline:
                  "Try diving for the first time <br /> and explore the underwater <br /> world with our instructors!",
              },
              PADICourses: {
                Headline: "PADI <br />DIVING <br />COURSES ",
                Subline:
                  "Do your first Open Water,<br/>Advanced or Rescue Diver<br/>PADI diving certification!",
              },
              CertifiedDivers: {
                Headline: "Certified<br/>Divers",
                Subline:
                  "Wrecks, Dolphins, Boat Dives.<br />Explore Athen's best diving<br />spots with our smiling staff!",
              },
              MarineDepartment: {
                Headline: "Marine<br />Department",
                Subline:
                  "RIB Boats, Water Safety, Professional Divers, <br />Underwater Art Direction and Photography. <br /> Contact our full-service marine department.",
              },
            },
            CertifiedDivers: {
              Hero: {
                Headline: "Every<br/>Diver<br/>welcome",
                Subline:
                  "Wrecks, Dolphins, Boat Dives.<br />Explore Athen's best diving<br />spots with our smiling staff!",
                Copy: "<b>Prerequisites:</b> Any Diving Certification<br /><b>Duration:</b> 4-5 hours<br /><b>Minimum age:</b> 18",
              },
              ContentOne: {
                Headline: "Dive with us<br />all around athens",
                Copy: "<p>There's an old wisdom: “If you travel to a new location or visit an unfamiliar dive environment, get a local orientation“. It is not only good advice, it is also easy to do!<br />Our Blue Oyster dive professionals know all the amazing spots around Athens, just join us, relax and enjoy the adventure.</p><p>Our certified and experienced dive guides will find the perfect diving experience for you. Based on your preferences, certifications and the weather conditions, we will take you to a walk in the bay's of Varkiza, ride a sunken motorcycle or search for dolphins and tunas. We regularly take our boats to explore the wrecks and visit hidden secrets of the Aegean.</p><p>And if you forgot to packt your own equipement, don't worry: we havea wide range of wetsuits, regulators and BCD's for rent.</p><p><b>Any certified diver is welcome at Blue Oyster!</b></p>",
              },
              ContentTwo: {
                Headline:
                  "<span class='view--item__overline'>Our Favourite Wreck: </span>SS Eleni<span class='view--item__subline'>A classic boat dive with a view.</span>",
                Copy: "<p>Our favourite wreck, the SS Eleni is a two hold bulk carrier that rests on the sand next to Patroklos island. The stern is at a depth of 16 metres and the bow at 30 metres.</p><p>Most of the once majestic ship has been destroyed by severe storms, but it'S remains rests a sandy seabed, with rays, weaver fishs, flounders and stripped mullets all around. Inside the wreck, we often see Groupers, morays and corbs.</p>",
              },
              ContentThree: {
                Headline:
                  "<span class='view--item__overline'>Our Most Famous Spot:</span>The Devil's Hole<span class='view--item__subline'>Dive down to 30 meters.</span>",
                Copy: "<p>The Devil's Hole, close to Vouliagmeni is the most famous dive site in Athens. Hidden inside a scenic rock formation at around 11 meters deep, is the entrance to the the Devils Hole. The entrance is just 3 meters wide, but the hole itself goes down to 30 meters. At the ground is a metal gate to prevent careless diver to enter the cave system hidden behind it.</p><p>It is a dive site for advanced and adventurous divers who lunge for an unusual dive. But it is not only the hole which makes this sive site special, the whole area is full of spectacular rock formations and you will stumble across the occassional octopus and squid.</p>",
              },
            },
            DSD: {
              Hero: {
                Headline: "Discover<br/>Scuba<br/>Diving",
                Subline:
                  "Try diving for the first time<br /> and explore the underwater<br /> world with our instructors!",
                Copy: "<b>Prerequisites:</b> None<br /><b>Duration:</b> 2-3 hours<br /><b>Minimum age:</b> 10",
              },
              ContentOne: {
                Headline:
                  "Your first breath<br/>under water!<span class='view--item__subline'>With PADI® Discover Scuba Diving Course at Blue Oyster</span>",
                Copy: "<p>Accompanied by our experienced instructors, you will get a first glimpse of the enchanting underwater world. First, you will get to know the basics of diving, the essential physics and how to use our professional <abbr title='self-contained underwater breathing apparatus'>SCUBA</abbr> equipment.</p>",
              },
              ContentTwo: {
                Headline: "About the course",
                Copy: "<p>Do you want to find out what scuba diving is like before deciding to get your PADI Open Water Diver scuba diving certification? During a Discover Scuba® Diving experience, you can try scuba diving for the first time. In a very short time, you’ll learn basic scuba skills and take your first breaths underwater.</p><p> Our highly-trained PADI® Professional will provide scuba gear, explain basic scuba diving skills and answer any questions you may have.</p>",
              },
              ContentThree: {
                Headline: "What you will learn",
                Copy: "<p>You will learn how to use the scuba gear and how to effectively communicate under water using hand signals. In addition, we will treach you basic scuba skills so that you feel comfortable under water.</p>",
              },
            },
            PADICourses: {
              Hero: {
                Headline: "PADI<br />Courses<br />in Athens",
                Subline:
                  "Get certified in Athens <br />and become a better diver <br /> with our experienced instructors!",
                Copy: "<b>Prerequisites:</b> Depends on the course<br /><b>Duration:</b> Depends on the course<br /><b>Minimum age:</b> 18",
              },
              ContentOne: {
                Headline: "PADI Courses",
                Copy: "<p> Do you dream of exploring the underwater world? Get certified with us!</p>",
              },
              ContentTwo: {
                Headline: "Open Water Diver",
                Overline:
                  "<span className='view--item__overline'>Start diving with us:</span>",
                Subline:
                  "<span className='view--item__subline'>You need to be at least 18 years old</span>",
                Copy: "<p>The Open Water Diver is your entry ticket to the underwarter world. This course is the first level of diving certifications. Once certified, you can dive safely to a maximum depth of 18 meters!</p>",
              },
              ContentThree: {
                Headline: "Advanced Open Water Diver",
                Overline:
                  "<span className='view--item__overline'>30 meters deep and more:</span>",
                Subline:
                  "<span className='view--item__subline'>Requires an Open Water certification</span>",
                Copy: "<p>This course builds on the training you received in the basic Open Water Diver course. With in five more dives you will focus on gaining more experience and and get certified to dive deeper. As an advanced diver, you are certified to a maximum depth of 30 meters!</p>",
              },
              ContentFour: {
                Headline: "Rescue Diver",
                Overline:
                  "<span className='view--item__overline'>Train for emergencies:</span>",
                Subline:
                  "<span className='view--item__subline'>Requires an Advanced Open Water certification</span>",
                Copy: "<p>The Rescue Diver course prepares you for dive emergencies, arming you with a variety of techniques. You will practice in-water resuscitation, you’ll work with an oxygen unit, floats, marker buoys and CPR mannequins. You will also become competent in assembling your own first aid kit, which will be useful throughout your diving career. Near the end of the course, your instructor will present you with hypothetical emergency scenarios, which you will respond to utilizing your new skills and knowledge.</p>",
              },
            },
            MarineDepartment: {
              Hero: {
                Headline: "Blue Oyster<br/>Marine Department",
                Subline:
                  "<span className='view--item__subline'>Water Satefy. Safety Divers. <br />Underwater Art Direction.<br />Boat Services.</span>",
                Copy: "",
              },
              ContentOne: {
                Headline: "",
                Copy: "<p>Our experienced marine department worked for independent productions, short movies and holywood block busters.</p><p>We provide equipment rentals, professional divers, certified dive guides, underwater photographers, specialised art directors, boats and experienced water safety professionals.</p><p>As a trusted partner, we will guide you throught the greek burocracy, contact us today to see how you can benefit from our partnership!</p>",
              },
            },
          },
        },
      },
      gr: {
        translation: {
          base: {
            title:
              "ΚΑΤΑΔΥΤΙΚΌ ΚΈΝΤΡΟ ΑΘΉΝΑ BLUE OYSTER. Μαθηματαy PADI στην Αθηνα & MARINE DEPARTMENT",
          },
          navigation: {
            Start: "Αρχή",
            PADIDSD: "Discover Scuba Diving",
            PADICourses: "PADI μαθήματα & πιστοποιήσεις",
            CertifiedDivers: "Πιστοποιημένους δύτες",
            MarineDepartment: "Marine Department",
            ContactUs: "Επικοινωνία",
          },
          button: {
            ContactUs: "Επικοινωνία",
            ReadMore: "Διάβασε περισσότερα",
            CTAHome: "Βουτήξτε κατευθείαν μέσα!",
          },

          page: {
            home: {
              Hero: {
                LineOne: "Καταδυση κεντρο",
                LineTwo: "BLUE OYSTER",
                LineThree: "μαθηματα &",
                LineFour: "UNDERWATER",
                LineFive: "SERVICES",
                Teaser:
                  "Θέλετε να <LinkDSD>ανακαλύψετε την κατάδυση</LinkDSD>, να αποκτήσετε <LinkPADICourses>καταδυτική πιστοποίηση</LinkPADICourses>, είστε <LinkCertifiedDivers>πιστοποιημένος δύτης</LinkCertifiedDivers> ή ψάχνετε για ένα έμπειρο  <LinkMarineDepartment> Marine Department</LinkMarineDepartment> στην Ελλάδα;",
              },
              DSD: {
                Headline: "DISCOVER<br />SCUBA<br />DIVING ",
                Subline:
                  "Δοκιμάστε την κατάδυση για πρώτη φορά <br /> και εξερευνήστε τον υποβρύχιο <br /> κόσμο με τους εκπαιδευτές μας!",
              },
              PADICourses: {
                Headline: "Μαθηματαy<br />PADI στην<br />Αθηνα",
                Subline:
                  "Κάντε την πρώτη σας πιστοποίηση <br/>κατάδυσης PADI Open Water, <br/>Advanced ή Rescue Diver!",
              },
              CertifiedDivers: {
                Headline: "Πιστοποιη-<br/>μενους δυτες",
                Subline:
                  "Ναυάγια, δελφίνια, καταδύσεις με σκάφος.<br />Εξερευνήστε τα καλύτερα καταδυτικά της Αθήνας <br />με το χαμογελαστό προσωπικό μας!",
              },
              MarineDepartment: {
                Headline: "Marine<br />Department",
                Subline:
                  "Σκάφη RIB, ασφάλεια στο νερό, επαγγελματίες δύτες,<br />υποβρύχια καλλιτεχνική διεύθυνση και φωτογραφία.<br />Επικοινωνήστε με το ναυτιλιακό μας τμήμα πλήρους εξυπηρέτησης.",
              },
            },
            CertifiedDivers: {
              Hero: {
                Headline: "Κάθε<br/>δύτης<br/>ευπρόσδεκτος",
                Subline:
                  "Ναυάγια, δελφίνια, καταδύσεις με σκάφος.<br />Εξερευνήστε τα καλύτερα καταδυτικά της Αθήνας <br />με το χαμογελαστό προσωπικό μας!",
                Copy: "<b>Προαπαιτούμενα:</b> Πιστοποίηση κατάδυσης<br /><b>Πιστοποίηση κατάδυσης:</b> 4-5 ώρες<br /><b>Ελάχιστη ηλικία:</b> 18",
              },
              ContentOne: {
                Headline: "Καταδυθείτε μαζί μας<br />σε όλη την Αθήνα",
                Copy: "<p>Υπάρχει μια παλιά σοφία: 'Αν ταξιδέψετε σε μια νέα τοποθεσία ή επισκεφθείτε ένα άγνωστο καταδυτικό περιβάλλον, πάρτε έναν τοπικό προσανατολισμό'. Δεν είναι μόνο καλή συμβουλή, είναι επίσης εύκολο να το κάνετε!<br/>Οι επαγγελματίες της Blue Oyster γνωρίζουν όλα τα καταπληκτικά σημεία κατάδυσης γύρω από την Αθήνα, απλά ελάτε μαζί μας, χαλαρώστε και απολαύστε την περιπέτεια.</p><p>Οι πιστοποιημένοι και έμπειροι οδηγοί μας θα βρουν την τέλεια καταδυτική εμπειρία για εσάς.</p><p>Με βάση τις προτιμήσεις σας, τις πιστοποιήσεις σας και τις καιρικές συνθήκες, θα σας πάμε σε μια βόλτα στον κόλπο της Βάρκιζας, θα οδηγήσουμε μια βυθισμένη μοτοσικλέτα ή θα ψάξουμε για δελφίνια και τόνους. Παίρνουμε τακτικά τα σκάφη μας για να εξερευνήσουμε τα ναυάγια και να επισκεφτούμε κρυμμένα μυστικά του Αιγαίου.</p><p>Και αν ξεχάσατε να πάρετε μαζί σας τον εξοπλισμό σας, μην ανησυχείτε: διαθέτουμε μια μεγάλη γκάμα από στολές κατάδυσης, ρυθμιστές και BCD για ενοικίαση.</p><p><b>Κάθε πιστοποιημένος δύτης είναι ευπρόσδεκτος στο Blue Oyster!</b></p>",
              },
              ContentTwo: {
                Headline:
                  "<span class='view--item__overline'>Το αγαπημένο μας ναυάγιο: </span>SS Eleni<span class='view--item__subline'>Μια κλασική κατάδυση με σκάφος και θέα.</span>",
                Copy: "<p>Το αγαπημένο μας ναυάγιο, το SS Eleni, είναι ένα πλοίο μεταφοράς χύδην φορτίου με δύο αμπάρια που βρίσκεται στην άμμο δίπλα στο νησί Πάτροκλος. Η πρύμνη βρίσκεται σε βάθος 16 μέτρων και η πλώρη σε βάθος 30 μέτρων.</p><p>Το μεγαλύτερο μέρος του άλλοτε μεγαλοπρεπούς πλοίου έχει καταστραφεί από τις ισχυρές καταιγίδες, αλλά τα απομεινάρια του ακουμπούν σε έναν αμμώδη βυθό, με σαλάχια, ψάρια-υφαντές, φούντες και απογυμνωμένες κέφαλες παντού γύρω του. Στο εσωτερικό του ναυαγίου, συχνά βλέπουμε γκρούπερς, σμέρνες και κορακοειδή.</p>",
              },
              ContentThree: {
                Headline:
                  "<span class='view--item__overline'>Το πιο διάσημο σημείο μας: </span>The Devil's Hole<span class='view--item__subline'>Κατάδυση σε βάθος 30 μέτρων.</span>",
                Copy: "<p>Η Τρύπα του Διαβόλου, κοντά στη Βουλιαγμένη, είναι το πιο διάσημο σημείο κατάδυσης στην Αθήνα. Κρυμμένη μέσα σε έναν γραφικό βραχώδη σχηματισμό σε βάθος περίπου 11 μέτρων, βρίσκεται η είσοδος της Τρύπας του Διαβόλου. Η είσοδος έχει πλάτος μόλις 3 μέτρα, αλλά η ίδια η τρύπα κατεβαίνει στα 30 μέτρα. Στο έδαφος υπάρχει μια μεταλλική πύλη που εμποδίζει απρόσεκτους δύτες να εισέλθουν στο σύστημα σπηλαίων που κρύβεται πίσω της. </p><p>Είναι ένα σημείο κατάδυσης για προχωρημένους και τολμηρούς δύτες που ορμούν σε μια ασυνήθιστη κατάδυση. Αλλά δεν είναι μόνο η τρύπα που κάνει αυτό το καταδυτικό μέρος ξεχωριστό, ολόκληρη η περιοχή είναι γεμάτη από εντυπωσιακούς βραχώδεις σχηματισμούς και θα πέσετε πάνω σε περιστασιακά χταπόδια και καλαμάρια.</p>",
              },
            },
            DSD: {
              Hero: {
                Headline: "Discover<br/>Scuba<br/>Diving",
                Subline:
                  "Δοκιμάστε την κατάδυση για πρώτη φορά<br /> και εξερευνήστε τον υποβρύχιο<br /> κόσμο με τους εκπαιδευτές μας!",
                Copy: "<b>Προαπαιτούμενα:</b> Καμία <br /><b>Πιστοποίηση κατάδυσης:</b> 2-3 ώρες<br /><b>Ελάχιστη ηλικία:</b> 10",
              },
              ContentOne: {
                Headline:
                  "Η πρώτη σας ανάσα<br/>κάτω από το νερό!<span class='view--item__subline'>Με PADI® Discover Scuba Diving Course στο Blue Oyster</span>",
                Copy: "<p> Συνοδευόμενοι από τους έμπειρους εκπαιδευτές μας, θα πάρετε μια πρώτη γεύση από τον μαγευτικό υποβρύχιο κόσμο. Αρχικά, θα γνωρίσετε τα βασικά στοιχεία της κατάδυσης, τη βασική φυσική και τον τρόπο χρήσης του επαγγελματικού μας εξοπλισμού <abbr title='self-contained underwater breathing apparatus'>SCUBA</abbr>.</p>",
              },
              ContentTwo: {
                Headline: "Σχετικά με το μάθημα",
                Copy: "<p>Θέλεις να μάθεις πώς είναι οι καταδύσεις πριν αποφασίσεις να αποκτήσεις την πιστοποίηση κατάδυσης PADI Open Water Diver; Κατά τη διάρκεια μιας εμπειρίας Discover Scuba® Diving, μπορείτε να δοκιμάσετε την κατάδυση για πρώτη φορά. Σε πολύ σύντομο χρονικό διάστημα, θα μάθεις τις βασικές δεξιότητες κατάδυσης και θα πάρεις τις πρώτες σου αναπνοές κάτω από το νερό.</p><p> Ο άρτια εκπαιδευμένος επαγγελματίας PADI® θα σου παρέχει τον εξοπλισμό κατάδυσης, θα σου εξηγήσει τις βασικές δεξιότητες κατάδυσης και θα απαντήσει σε κάθε σου απορία.</p>",
              },
              ContentThree: {
                Headline: "Τι θα μάθετε",
                Copy: "<p>Θα μάθετε πώς να χρησιμοποιείτε τον εξοπλισμό κατάδυσης και πώς να επικοινωνείτε αποτελεσματικά κάτω από το νερό χρησιμοποιώντας σήματα χειρός. Επιπλέον, θα σας διδάξουμε βασικές δεξιότητες κατάδυσης ώστε να νιώθετε άνετα κάτω από το νερό.</p>",
              },
            },
            PADICourses: {
              Hero: {
                Headline: "Μαθήματαy<br />PADI<br />στην Αθήνα",
                Subline:
                  "Πιστοποιηθείτε στην Αθήνα <br />και γίνετε καλύτεροι δύτες <br /> με τους έμπειρους εκπαιδευτές μας!",
                Copy: "<b>Προαπαιτούμενα:</b> Εξαρτάται από το μάθημα <br /><b>Πιστοποίηση κατάδυσης:</b> Εξαρτάται από το μάθημα<br /><b>Ελάχιστη ηλικία:</b> 10",
              },
              ContentOne: {
                Headline: "Μαθήματα PADI",
                Copy: "<p>Ονειρεύεστε να εξερευνήσετε τον υποβρύχιο κόσμο; Πιστοποιηθείτε μαζί μας!</p>",
              },
              ContentTwo: {
                Headline: "Open Water Diver",
                Overline:
                  "<span className='view--item__overline'>Ξεκινήστε τις καταδύσεις μαζί μας:</span>",
                Subline:
                  "<span className='view--item__subline'>Θα πρέπει να είστε τουλάχιστον 18 ετών</span>",
                Copy: "<p>Το Open Water Diver είναι το εισιτήριο εισόδου σας στον υποβρύχιο κόσμο. Αυτό το μάθημα είναι το πρώτο επίπεδο πιστοποίησης καταδύσεων. Μόλις πιστοποιηθείτε, μπορείτε να καταδυθείτε με ασφάλεια σε μέγιστο βάθος 18 μέτρων!</p>",
              },
              ContentThree: {
                Headline: "Advanced Open Water Diver",
                Overline:
                  "<span className='view--item__overline'>30 μέτρα βάθος και άνω:</span>",
                Subline:
                  "<span className='view--item__subline'>Απαιτείται πιστοποίηση ανοικτής θάλασσας</span>",
                Copy: "<p>Αυτό το μάθημα βασίζεται στην εκπαίδευση που λάβατε στο βασικό μάθημα Open Water Diver. Με πέντε ακόμη καταδύσεις θα επικεντρωθείτε στο να αποκτήσετε περισσότερη εμπειρία και να πιστοποιηθείτε για να καταδυθείτε βαθύτερα. Ως προχωρημένος δύτης, πιστοποιείστε σε μέγιστο βάθος 30 μέτρων!</p>",
              },
              ContentFour: {
                Headline: "Rescue Diver",
                Overline:
                  "<span className='view--item__overline'>Εκπαιδευτείτε για καταστάσεις έκτακτης ανάγκης:</span>",
                Subline:
                  "<span className='view--item__subline'>Απαιτείται πιστοποίηση Advanced Open Water</span>",
                Copy: "<p>Το μάθημα Rescue Diver σας προετοιμάζει για καταδυτικά επείγοντα περιστατικά, οπλίζοντάς σας με μια ποικιλία τεχνικών. Θα εξασκηθείτε στην ανάνηψη μέσα στο νερό, θα εργαστείτε με μια μονάδα οξυγόνου, πλωτήρες, σημαδούρες και κούκλες καρδιοαναπνευστικής αναζωογόνησης. Θα γίνετε επίσης ικανοί να συναρμολογήσετε το δικό σας κουτί πρώτων βοηθειών, το οποίο θα σας φανεί χρήσιμο καθ' όλη τη διάρκεια της καταδυτικής σας καριέρας. Κοντά στο τέλος του μαθήματος, ο εκπαιδευτής σας θα σας παρουσιάσει υποθετικά σενάρια έκτακτης ανάγκης, στα οποία θα ανταποκριθείτε χρησιμοποιώντας τις νέες σας δεξιότητες και γνώσεις.</p>",
              },
            },
            MarineDepartment: {
              Hero: {
                Headline: "Blue Oyster<br/>Marine Department",
                Subline:
                  "<span className='view--item__subline'>Ασφάλεια στο νερό. Ασφάλεια δυτών.<br/>Υποβρύχια καλλιτεχνική διεύθυνση.<br/>Υπηρεσίες σκαφών.</span>",
                Copy: "",
              },
              ContentOne: {
                Headline: "",
                Copy: "<p>Το έμπειρο θαλάσσιο τμήμα μας εργάστηκε για ανεξάρτητες παραγωγές, ταινίες μικρού μήκους και holywood block busters.</p><p>Διαθέτουμε ενοικιάσεις εξοπλισμού, επαγγελματίες δύτες, πιστοποιημένους οδηγούς κατάδυσης, υποβρύχιους φωτογράφους, εξειδικευμένους καλλιτεχνικούς διευθυντές, σκάφη και έμπειρους επαγγελματίες για την ασφάλεια στο νερό.</p><p>Ως έμπιστος συνεργάτης, θα σας καθοδηγήσουμε μέσα από την ελληνική γραφειοκρατία, επικοινωνήστε μαζί μας σήμερα για να δείτε πώς μπορείτε να επωφεληθείτε από τη συνεργασία μας!</p>",
              },
            },
          },
        },
      },
    },
  });

export default i18n;
