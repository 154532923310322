import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";

import hashScroll from "./hashscroll";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";

function Dsd() {
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage || i18n.defaultLocale;
  useEffect(() => {
    hashScroll();
  });
  return (
    <div id="dsd">
      <Helmet htmlAttributes={{ lang }}>
        <title>
          {t("base.title")} - {t("navigation.DSD")}
        </title>
      </Helmet>
      <div
        className="view--item view--cover view--bg-300"
        style={{
          backgroundImage: "url('/static/images/dsd-1.jpg')",
        }}
      >
        <div className="view--item__content view--item ">
          <div className="view--item__home-teaser">
            <h1>
              <Trans
                i18nKey="page.DSD.Hero.Headline"
                components={{
                  br: <br />,
                }}
              />
              <span className="view--item__subline">
                <Trans
                  i18nKey="page.DSD.Hero.Subline"
                  components={{
                    br: <br />,
                  }}
                />
              </span>{" "}
            </h1>
            <p>
              <Trans
                i18nKey="page.DSD.Hero.Copy"
                components={{
                  br: <br />,
                  b: <b />,
                }}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="view--item view--bg-100 view--colors-dark">
        <div className="view--item__content">
          <h1>
            <Trans
              i18nKey="page.DSD.ContentOne.Headline"
              components={{
                br: <br />,
                span: <span />,
              }}
            />
          </h1>
          <Trans
            i18nKey="page.DSD.ContentOne.Copy"
            components={{
              p: <p />,
              br: <br />,
              b: <b />,
              abbr: <abbr />,
            }}
          />
          <Link to="contact" className="button--cta">
            <span className="button--cta__text">{t("button.ContactUs")}</span>
          </Link>
        </div>
      </div>

      <div className="view--item view--bg-300 view--colors-dark">
        <div className="view--item__content">
          <h1>
            <Trans
              i18nKey="page.DSD.ContentTwo.Headline"
              components={{
                br: <br />,
              }}
            />
          </h1>
          <Trans
            i18nKey="page.DSD.ContentTwo.Copy"
            components={{
              p: <p />,
              br: <br />,
              b: <b />,
            }}
          />
        </div>
      </div>

      <div className="view--item view--bg-500 view--colors-medium">
        <div className="view--item__content">
          <h1>
            <Trans
              i18nKey="page.DSD.ContentThree.Headline"
              components={{
                br: <br />,
              }}
            />
          </h1>
          <Trans
            i18nKey="page.DSD.ContentThree.Copy"
            components={{
              p: <p />,
              br: <br />,
              b: <b />,
            }}
          />
        </div>
      </div>

      {/* <div className="view--item view--bg-600 view--colors-light">
        <div className="view--item__content">
          <h1>Headline</h1>
          <p>Lorem</p>
        </div>
      </div>

      <div className="view--item view--bg-800 view--colors-light">
        <div className="view--item__content">
          <h1>Headline</h1>
          <p>Lorem</p>
        </div>
      </div> */}
    </div>
  );
}

export default Dsd;
