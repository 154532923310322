import React, { useState } from "react";
import {} from "react-router-dom";
import "./Footer.css";
import {
  FaRegEnvelope,
  FaPhoneAlt,
  FaInstagram,
  FaExternalLinkAlt,
} from "react-icons/fa";

function Footer({ onClick }) {
  return (
    <div id="Footer" className="footer view--bg-950 flexbox">
      <img
        src="./static/logo/png/logo-light.png"
        className="header--logo"
        style={{ width: "245px", opacity: "0.5" }}
        alt="Blue Oyster: PADI Diving center and underwarter services in Athens"
      />
      <address className="footer--address" id="contact">
        <b>BLUE OYSTER</b>
        <br />
        Chrysostomou Smyrnis 24
        <br />
        Ilioupoli
        <br />
        16345 ATTICA
      </address>
      <address className="footer--address">
        <a href="mailto:blueoyster.dive@gmail.com">
          <FaRegEnvelope />
          blueoyster.dive@gmail.com
        </a>

        <a href="tel:00306972295891">
          <FaPhoneAlt />
          (+30) 00306972295891
        </a>

        <a href="https://www.instagram.com/blueoysterdive">
          <FaInstagram />
          @blueoysterdive
        </a>
        <a rhef="https://www.padi.com/dive-center/greece/blue-oyster-diving-center/">
          <FaExternalLinkAlt />
          Blue Oyster at PADI.com
        </a>
      </address>

      <span className="flexbox--spacer"></span>
    </div>
  );
}

export default Footer;
