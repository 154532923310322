import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import "./Navigation.css";
import { useTranslation, Trans } from "react-i18next";
import { useCookies } from "react-cookie";

const lngs = {
  en: { nativeName: "English" },
  gr: { nativeName: "Ελληνικά" },
};

function Navigation({ onClick }) {
  const { t, i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["i18n"]);
  function setI18n(lng) {
    i18n.changeLanguage(lng);
    setCookie("i18n", lng);
  }
  return (
    <nav id="navigation" className="navigation">
      <button
        className="navigation--button navigation--button__close"
        onClick={() => onClick("close")}
      >
        <svg
          width="32"
          height="32"
          fill="none"
          className="navigation--button__svg"
        >
          <path
            stroke="#525453"
            d="M20.914 20l9.293-9.293L29.5 10l-9.293 9.293L10.914 10l-.707.707L19.5 20 10 29.5l.707.707 9.5-9.5 9.5 9.5.707-.707-9.5-9.5z"
            fill="#fff"
          ></path>
        </svg>
      </button>
      <ul className="navigation--list">
        <li className="navigation--list__item">
          {/* <NavLink exact to={i18n.resolvedLanguage + "/"}> */}
          <NavLink exact to={"/"}>
            {t("navigation.Start")}
          </NavLink>
        </li>
        <li className="navigation--list__item">
          <NavLink exact to="/discover-scuba-diving">
            {t("navigation.PADIDSD")}
          </NavLink>
        </li>
        <li className="navigation--list__item">
          <NavLink exact to="/padi-courses">
            {t("navigation.PADICourses")}
          </NavLink>
        </li>
        <li className="navigation--list__item">
          <NavLink exact to="/certified-divers">
            {t("navigation.CertifiedDivers")}
          </NavLink>
        </li>
        <li className="navigation--list__item">
          <NavLink exact to="/marine-department">
            {t("navigation.MarineDepartment")}
          </NavLink>
        </li>
        <li className="navigation--list__item">
          <NavLink exact to="/contact">
            {t("navigation.ContactUs")}
          </NavLink>
        </li>
        {/*          
        <li className="navigation--list__item">
          <NavLink exact to="/impressum">
            Impressum
          </NavLink>
        </li>
        <li className="navigation--list__item">
          <NavLink exact to="/kontakt">
            Kontakt
          </NavLink>
        </li> */}
      </ul>
      <div>
        {Object.keys(lngs).map((lng) => (
          <button
            className={`navigation-lang--button ${
              i18n.resolvedLanguage === lng ? "active" : ""
            }`}
            key={lng}
            type="submit"
            onClick={() => setI18n(lng)}
          >
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>
      {/* <section>Second Row</section> */}
    </nav>
  );
}

export default Navigation;
