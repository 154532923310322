import React, { useState } from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation, Trans } from "react-i18next";

import Home from "./Home";

import Dsd from "./Dsd";
import Courses from "./Courses";
import CertifiedDivers from "./CertifiedDivers";
import MarineDepartment from "./MarineDepartment";
import Imprint from "./Imprint";
import Contact from "./Contact";

import Footer from "./Footer";
import Header from "./Header";
import Navigation from "./Navigation";
import NavigationOverlay from "./NavigationOverlay";

import "./Variables.css";
import "./Main.css";
import "./Fx.css";
import "./View.css";
import "./Flexbox.css";
import "./Buttons.css";
import "./ColorSchemes.css";

import { TweenLite } from "gsap";
import { gsap } from "gsap/dist/gsap";
import { useCookies } from "react-cookie";

const animationDistance = 30;
const animateOnEnter = (location, node, appears) => {
  // console.log("animateOnEnter", node.id, location, appears);

  // home
  if (node?.id === "home") {
    let header = document.querySelector(".header");
    let headerDistance = -1 * document.querySelector(".header").clientHeight;
    gsap.fromTo(
      header,
      { y: headerDistance, opacity: 0 },
      {
        duration: 0.5,
        delay: 1,
        ease: "power4.out",
        y: 0,
        opacity: 1,
      }
    );
    let headerLogo = document.querySelector(".header--logo");
    let headerLogoDistance = -1 * 20;
    gsap.fromTo(
      headerLogo,
      { x: headerLogoDistance, opacity: 0 },
      {
        duration: 0.35,
        delay: 1.5,
        ease: "power2.out",
        x: 0,
        opacity: 1,
      }
    );
    let headerButton = document.querySelector(".navigation--button__open");
    let headerButtonDistance = 1 * 20;
    gsap.fromTo(
      headerButton,
      { x: headerButtonDistance, opacity: 0 },
      {
        duration: 0.35,
        delay: 1.7,
        ease: "power2.out",
        x: 0,
        opacity: 1,
      }
    );

    let lines = document.querySelectorAll(".fx--home__line");
    Array.from(lines).forEach((node, index) => {
      gsap.fromTo(
        node,
        { x: -20, opacity: 0 },
        {
          duration: 0.35,
          delay: 2 + index * 0.2,
          ease: "power2.out",
          x: 0,
          opacity: 1,
        }
      );
    });

    let down = document.querySelectorAll(".fx--home__down");
    Array.from(down).forEach((node, index) => {
      gsap.fromTo(
        node,
        { y: -40, opacity: 0 },
        {
          duration: 1,
          delay: 3.5 + index * 0.2,
          ease: "bounce",
          y: 0,
          opacity: 1,
        }
      );
    });

    // content
    gsap.fromTo(
      node,
      { x: -1 * animationDistance, opacity: 0 },
      {
        duration: 1,
        delay: 0,
        ease: "power2.out",
        x: 0,
        opacity: 1,
      }
    );
  } else {
    // content
    gsap.fromTo(
      node,
      { x: -1 * animationDistance, opacity: 0 },
      {
        duration: 1,
        delay: 0,
        ease: "power2.out",
        x: 0,
        opacity: 1,
      }
    );
  }
};

const animateOnExit = (location, node, appears) => {
  // // console.log("animateOnExit", node.id, location, appears);
  // gsap.fromTo(
  // 	node,
  // 	{ x: 0, opacity: 1 },
  // 	{
  // 		duration: 0.5,
  // 		delay: 0,
  // 		ease: "power2.in",
  // 		x: animationDistance,
  // 		opacity: 0
  // 	}
  // );
};

function Main() {
  const [navState, navStateFn] = useState("close");
  const setNavState = (value) => {
    navStateFn(value);
  };
  const [cookies, setCookie] = useCookies(["i18n"]);
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage || i18n.defaultLocale;

  return (
    <>
      <Helmet htmlAttributes={{ lang }}>
        <title>{t("base.title")}</title>
        <meta
          name="description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
        <meta
          property="og:title"
          content="BLUE OYSTER. DIVING CENTER ATHENS. PADI COURSES & MARINE DEPARTMENT."
        />
        <meta
          property="og:description"
          content="PADI certification, fun diving and experienced marine department. We have you covered."
        />
      </Helmet>
      <Router>
        <Route
          render={({ location }) => (
            <div className={navState === "open" ? "fx--navigation__open" : ""}>
              <Route
                exact
                // path="/"
                // render={() => <Redirect to="/redirect" />}
              />
              <Navigation onClick={setNavState}></Navigation>
              <NavigationOverlay></NavigationOverlay>
              <Header onClick={setNavState}></Header>
              <div className="view__wrapper">
                <TransitionGroup>
                  <Transition
                    key={location.key}
                    appear={true}
                    onEnter={(node, appears) => {
                      animateOnEnter(location, node, appears);
                      setNavState("close");
                    }}
                    onExit={(node, appears) =>
                      animateOnExit(location, node, appears)
                    }
                    timeout={{ enter: 500, exit: 0 }}
                  >
                    <Switch location={location}>
                      <Route path="/" exact component={Home} />
                      <Route
                        path="/discover-scuba-diving"
                        exact
                        component={Dsd}
                      />
                      <Route path="/padi-courses" exact component={Courses} />
                      <Route
                        path="/certified-divers"
                        exact
                        component={CertifiedDivers}
                      />
                      <Route
                        path="/marine-department"
                        exact
                        component={MarineDepartment}
                      />
                      <Route path="/contact" exact component={Contact} />
                      <Route path="/impressum" exact component={Imprint} />
                      <Route path="/kontakt" exact component={Contact} />
                      // 404
                      <Route render={() => <Redirect to="/" />} />
                    </Switch>
                  </Transition>
                </TransitionGroup>
              </div>
              <Footer></Footer>
            </div>
          )}
        />
      </Router>
    </>
  );
}

export default Main;
